import InvoiceIcon from '@mui/icons-material/LibraryBooks';
import ReportList from "./ReportsList";


export default {
    list: ReportList,
    icon: InvoiceIcon,
    options: {label: "Жалобы"},
};

